<div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading$(this) | async">

  <div class="row">
    <icz-form-field
      class="grow"
      formControlName="name"
      label="Název"
    ></icz-form-field>
  </div>
  <div class="row">
    <icz-form-autocomplete
      class="grow"
      formControlName="officeDeskCategoryId"
      label="Kategorie úřední desky"
      [options]="officeDeskCategoryOptions"
    ></icz-form-autocomplete>
  </div>
  <div class="row">
    <icz-form-autocomplete
      class="grow"
      formControlName="officeDeskRegionId"
      label="Oblast úřední desky"
      [options]="officeDeskRegionOptions"
    ></icz-form-autocomplete>
  </div>
  <div class="row">
    <icz-date-picker
      class="grow"
      formControlName="toPostFrom"
      [selectableDates]="presentOrFutureDateValidator"
      label="Vyvěsit od"
    ></icz-date-picker>
    <icz-form-field
      class="grow" type="integer"
      formControlName="postingDuration"
      label="Vyvěsit dní"
    ></icz-form-field>
    <icz-date-picker
      class="grow"
      formControlName="toPostTo"
      [selectableDates]="presentOrFutureDateValidator"
      label="Vyvěsit do"
    ></icz-date-picker>
  </div>
  <div class="row">
    <icz-form-field
      class="grow"
      formControlName="originatorName"
      label="Původce jméno"
    ></icz-form-field>
    <icz-form-field
      class="grow"
      formControlName="originatorAddress"
      label="Původce adresa"
    ></icz-form-field>
  </div>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="manualPost()" primary label="Vyvěsit"></icz-button>
  <icz-button rightButtons [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
